.PhoneInputInput {
    border-color: transparent;
    font-family: "Montserrat";
    letter-spacing: inherit;
    color: #ababab;
    font-weight: 400;
    font-size: 1rem;
    height: 35px;
    line-height: 1.4375em;
    box-sizing: border-box;
    outline: none;
}