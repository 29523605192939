.progress {
  height: 2px;
  background-color: #777;
  position: relative;
  width: 90%;
  margin: 0 5px;
}

.progress .progress-bar {
  position: absolute;
  height: 100%;
  background-color: #0ac499;
  animation: fill 3s infinite;
}

.completed-bar {
  height: 100%;
  width: 100%;
  background-color: #0ac499;
}

@keyframes fill {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}
