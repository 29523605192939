html,
body,
#root {
  /* min-height: 100%;  */ /* deveria ser min-height aqui */
  height: 100%;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* prevent PWA pull-down to refresh */
  overscroll-behavior-y: contain;

  /*  Disable highlighting */
  -webkit-tap-highlight-color: transparent;

  /* Disable callouts */
  -webkit-touch-callout: none;

  /* disable user text selection */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
}
