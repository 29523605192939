/* chat da direita */
.talk-bubble-right {
  display: flex;
  justify-content: flex-end;
  position: relative;
  width: 260px;
  height: auto;
  background-color: white;
  margin-bottom: 8px;
  margin-left: auto;
}

.tri-right.btm-right.show-tip:after {
  content: " ";
  position: absolute;
  right: 0px;
  bottom: -21px;
  border: 12px solid;
  border-radius: 12px 0px 5px 0px;
  border-color: black black transparent transparent;
}

.tri-right.btm-right.show-tip > .triangle {
  content: " ";
  position: absolute;
  z-index: 2;
  right: 3px;
  bottom: -14px;
  border: 9px solid;
  border-color: white white transparent transparent;
}

.talktext-right {
  /* width: 100%; */
  padding: 24px 16px;
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  border-radius: 10px 10px 0px 10px;
  border: 3px solid;
  color: black;
}

.talktext-right p {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

/* chat da esquerda */
.talk-bubble-left {
  left: 0;
  position: relative;
  max-width: 260px;
  display: inline-block;
  height: auto;
  border-radius: 10px 10px 10px 0px;
  margin-top: 8px;
  margin-right: auto;
  background-color: black;
}

.talk-bubble-left.show-tip {
  margin-bottom: 24px;
}

.talk-bubble-right.show-tip {
  margin-bottom: 24px;
}

.tri-left.btm-left.show-tip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  bottom: -21px;
  border-radius: 0px 12px 0px 5px;
  border: 12px solid;
  left: auto;
  border-color: black transparent transparent black;
}

.talktext-left {
  flex-grow: 0;
  padding: 24px 16px;
  text-align: left;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffff;
}

.talktext-left p {
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;
}

.message {
  word-break: break-word;
}
