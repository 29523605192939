.MuiCalendarPicker-root > div:first-child {
  background-color: black;
  color: white;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 12px;
  max-height: 52px;
}

.MuiCalendarPicker-root > div:first-child div {
  font-family: "Courier Prime", monospace;
  text-transform: capitalize;
  font-weight: 700;
}

.MuiInputBase-root > input {
  font-family: "Montserrat";
  color: "black";
  font-weight: 300;
}

.sc-caiLqq.gHEbSl.PrivateTimePickerToolbar-hourMinuteLabel {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.sc-gsDKAQ.hBCOXZ.PrivatePickersToolbar-root.sc-cidDSM.wBcUW {
  padding: 24px 0px 0px;
}

button.MuiButtonBase-root-KjFju.gUleGd.MuiButtonBase-root.MuiIconButton-root-jYvkJQ.fWtnqt.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium:last-child {
  visibility: hidden;
}

span.MuiTypography-root-bWKFam.fGwyh.MuiTypography-root.MuiTypography-h3.sc-gWXbKe.eLdcfC.Mui-selected {
  color: white;
}

span.MuiTypography-root-bWKFam.fGwyh.MuiTypography-root.MuiTypography-h3.sc-gWXbKe.eLdcfC {
  color: #000000;
  border: 4px solid #000000;
  box-sizing: border-box;
  width: 96px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Courier Prime;
  font-style: normal;
  font-weight: bold;
  font-size: 56px;
  line-height: 63px;
  border-radius: 4px;
  letter-spacing: -0.075em;
}

span.MuiTypography-root-bWKFam.fGwyh.MuiTypography-root.MuiTypography-h3.sc-gWXbKe.eLdcfC:nth-child(2) {
  border: none;
  position: static;
  width: 1px;
  height: 80px;
  left: calc(50% - 1px / 2 - 31.5px);
  top: 0px;
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 64px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #202124;
  margin: 0px 12px;
}

.MuiButtonBase-root .Mui-selected {
  background-color: black;
}

.MuiCalendarPicker-root > div:first-child .MuiButtonBase-root {
  color: black;
}

.MuiCalendarPicker-root > div:last-child .MuiPickersDay-dayWithMargin {
  font-family: "Montserrat", sans-serif;
  border-color: black;
  font-size: 16px;
}

.MuiCalendarPicker-root .MuiTypography-caption {
  font-size: 16px;
  font-weight: 600;
  color: #ababab;
}

.MuiCalendarPicker-root .PrivatePickersSlideTransition-root {
  min-height: 230px;
}

.MuiDialogActions-root > button:last-child {
  color: white;
  background-color: black;
  border-radius: 4px;
}

.MuiDialogActions-root > button {
  font-family: Montserrat;
  font-weight: 600;
  color: #000000;
  margin-right: 8px;
}
