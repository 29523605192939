.menu-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.menu-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.menu-exit {
  opacity: 1;
}

.menu-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

.link-button:active {
  opacity: 0.5;
}
